import $ from 'jquery'
import 'core-js/es/number'
import 'objectFitPolyfill'
import 'lazysizes/plugins/object-fit/ls.object-fit'
import 'lazysizes/plugins/parent-fit/ls.parent-fit'
import 'lazysizes/plugins/respimg/ls.respimg'
import Swiper, { Navigation, A11y, Autoplay, Pagination } from 'swiper/swiper.esm'
import 'swiper/swiper-bundle.css'

Swiper.use([Navigation, A11y, Autoplay, Pagination])

class HeroSlider extends window.HTMLDivElement {
  constructor (...args) {
    const self = super(...args)
    self.init()
    return self
  }

  init () {
    this.$ = $(this)
    this.props = this.getInitialProps()
    this.resolveElements()
  }

  getInitialProps () {
    let data = {}
    try {
      data = JSON.parse($('script[type="application/json"]', this).text())
    } catch (e) {}
    return data
  }

  resolveElements () {
    this.$slider = $('[data-slider]', this)
    this.$sliderItems = $('.swiper-slide', this)
    this.$buttonNext = $('[data-slider-button="next"]', this)
    this.$buttonPrev = $('[data-slider-button="prev"]', this)
    this.$pagination = $('[data-slider-pagination]', this)
  }

  connectedCallback () {
    if (this.$sliderItems.length > 1) {
      this.initSlider()
    }
  }

  initSlider () {
    const nav = document.getElementById('nav-inner-wrapper');
    const { options } = this.props
    const config = {
      a11y: options.a11y,
      navigation: {
        nextEl: this.$buttonNext.get(0),
        prevEl: this.$buttonPrev.get(0)
      },
      slidesPerView: 1,
      pagination: {
        el: this.$pagination.get(0),
        clickable: true
      },
	  on: {
		slideChangeTransitionStart: function () {
          $('#nav-inner-wrapper').hide(0);
		  $('.swiper-content').hide(0);
		  $('#nav-container').removeClass('aos-init').removeClass('aos-animate');
          $('.swiper-content').removeClass('aos-init').removeClass('aos-animate');
		  nav.removeAttribute('class');
		  //$('.slider-buttons').addClass( $('.swiper-slide').attr('class') );
        },
        slideChangeTransitionEnd: function () {
          $('#nav-inner-wrapper').show(0);
		  $('.swiper-content').show(0);
		  $('#nav-inner-wrapper').attr('class',$('.swiper-slide-active').attr('class'));
          AOS.init();
        },
      } 
    }

    if (options.autoplay && options.autoplaySpeed) {
      config.autoplay = {
        delay: options.autoplaySpeed
      }
    }

    this.swiper = new Swiper(this.$slider.get(0), config);
  }
  
}

window.customElements.define('totem-hero-slider', HeroSlider, { extends: 'div' })